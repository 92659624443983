



    *,
    :after,
    :before {
        box-sizing: border-box;
        border: 0 solid #e5e7eb
    }

    :after,
    :before {
        --tw-content: ""
    }

    :host,
    html {
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        -moz-tab-size: 4;
        -o-tab-size: 4;
        tab-size: 4;
        font-family: var(--font-sf);
        font-feature-settings: normal;
        font-variation-settings: normal;
        -webkit-tap-highlight-color: transparent
    }

    body {
        margin: 0;
        line-height: inherit
    }

    hr {
        height: 0;
        color: inherit;
        border-top-width: 1px
    }

    abbr:where([title]) {
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit
    }

    a {
        color: inherit;
        text-decoration: inherit
    }

    b,
    strong {
        font-weight: bolder
    }

    code,
    kbd,
    pre,
    samp {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-size: 1em
    }

    small {
        font-size: 80%
    }

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline
    }

    sub {
        bottom: -.25em
    }

    sup {
        top: -.5em
    }

    table {
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-feature-settings: inherit;
        font-variation-settings: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        color: inherit;
        margin: 0;
        padding: 0
    }

    button,
    select {
        text-transform: none
    }

    button,
    input:where([type=button]),
    input:where([type=reset]),
    input:where([type=submit]) {
        -webkit-appearance: button;
        background-color: transparent;
        background-image: none
    }

    :-moz-focusring {
        outline: auto
    }

    :-moz-ui-invalid {
        box-shadow: none
    }

    progress {
        vertical-align: baseline
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        height: auto
    }

    [type=search] {
        -webkit-appearance: textfield;
        outline-offset: -2px
    }

    ::-webkit-search-decoration {
        -webkit-appearance: none
    }

    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit
    }

    summary {
        display: list-item
    }

    blockquote,
    dd,
    dl,
    figure,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    pre {
        margin: 0
    }

    fieldset {
        margin: 0
    }

    fieldset,
    legend {
        padding: 0
    }

    menu,
    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    dialog {
        padding: 0
    }

    textarea {
        resize: vertical
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
        opacity: 1;
        color: #9ca3af
    }

    input::placeholder,
    textarea::placeholder {
        opacity: 1;
        color: #9ca3af
    }

    [role=button],
    button {
        cursor: pointer
    }

    :disabled {
        cursor: default
    }

    audio,
    canvas,
    embed,
    iframe,
    img,
    object,
    svg,
    video {
        display: block;
        vertical-align: middle
    }

    img,
    video {
        max-width: 100%;
        height: auto
    }

    [hidden] {
        display: none
    }

    :root {
        --background: 0 0% 100%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 46.1%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 10% 3.9%;
        --radius: 0.5rem;
        --poly-red-light: 0, 100%, 65%;
        --poly-blue-light: 216, 100%, 64%;
        --poly-yellow-light: 47, 100%, 68%;
        --poly-orange-light: 25, 100%, 69%;
        --poly-blue: 216, 100%, 16%;
        --poly-yellow: 46, 100%, 12%;
        --poly-orange: 25, 100%, 16%;
        --poly-red: 0, 100%, 15%;
        --poly-green: 144, 100%, 31%;
        --poly-roulette-green: 144, 100%, 31%;
        --poly-roulette-red: 359, 48%, 43%;
        --poly-roulette-black: 0, 0%, 0%;
        --poly-roulette-selected: 158, 86%, 48%;
        --poly-roulette-shadow-selected: 158, 86%, 48%, 0.25;
        --poly-toast-lost-border: 0 58% 87%;
        --poly-toast-lost-bg: 0 67% 96%;
        --poly-toast-lost-text: 5 74% 47%;
        --poly-toast-won-border: 119 39% 83%;
        --poly-toast-won-bg: 137 78% 96%;
        --poly-toast-won-text: 144 100% 31%
    }

    .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;
        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
        --poly-blue: 216, 100%, 64%;
        --poly-yellow: 47, 100%, 68%;
        --poly-orange: 25, 100%, 69%;
        --poly-red: 0, 100%, 65%;
        --poly-green: 133, 89%, 65%;
        --poly-toast-lost-border: 5 76% 25%;
        --poly-toast-lost-bg: 4 91% 9%;
        --poly-toast-lost-text: 5 74% 47%;
        --poly-toast-won-border: 144.3 100% 15.49%;
        --poly-toast-won-bg: 143.68 100% 7.45%;
        --poly-toast-won-text: 144.08 100% 30.78%
    }

    * {
        border-color: hsl(var(--border))
    }

    body {
        background-color: hsl(var(--background));
        color: hsl(var(--foreground))
    }

    *,
    :after,
    :before {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x: ;
        --tw-pan-y: ;
        --tw-pinch-zoom: ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position: ;
        --tw-gradient-via-position: ;
        --tw-gradient-to-position: ;
        --tw-ordinal: ;
        --tw-slashed-zero: ;
        --tw-numeric-figure: ;
        --tw-numeric-spacing: ;
        --tw-numeric-fraction: ;
        --tw-ring-inset: ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgba(59, 130, 246, .5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur: ;
        --tw-brightness: ;
        --tw-contrast: ;
        --tw-grayscale: ;
        --tw-hue-rotate: ;
        --tw-invert: ;
        --tw-saturate: ;
        --tw-sepia: ;
        --tw-drop-shadow: ;
        --tw-backdrop-blur: ;
        --tw-backdrop-brightness: ;
        --tw-backdrop-contrast: ;
        --tw-backdrop-grayscale: ;
        --tw-backdrop-hue-rotate: ;
        --tw-backdrop-invert: ;
        --tw-backdrop-opacity: ;
        --tw-backdrop-saturate: ;
        --tw-backdrop-sepia: ;
        --tw-contain-size: ;
        --tw-contain-layout: ;
        --tw-contain-paint: ;
        --tw-contain-style:
    }

    ::backdrop {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x: ;
        --tw-pan-y: ;
        --tw-pinch-zoom: ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position: ;
        --tw-gradient-via-position: ;
        --tw-gradient-to-position: ;
        --tw-ordinal: ;
        --tw-slashed-zero: ;
        --tw-numeric-figure: ;
        --tw-numeric-spacing: ;
        --tw-numeric-fraction: ;
        --tw-ring-inset: ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgba(59, 130, 246, .5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur: ;
        --tw-brightness: ;
        --tw-contrast: ;
        --tw-grayscale: ;
        --tw-hue-rotate: ;
        --tw-invert: ;
        --tw-saturate: ;
        --tw-sepia: ;
        --tw-drop-shadow: ;
        --tw-backdrop-blur: ;
        --tw-backdrop-brightness: ;
        --tw-backdrop-contrast: ;
        --tw-backdrop-grayscale: ;
        --tw-backdrop-hue-rotate: ;
        --tw-backdrop-invert: ;
        --tw-backdrop-opacity: ;
        --tw-backdrop-saturate: ;
        --tw-backdrop-sepia: ;
        --tw-contain-size: ;
        --tw-contain-layout: ;
        --tw-contain-paint: ;
        --tw-contain-style:
    }

    .rouletteGame { 

    .container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 2rem;
        padding-left: 2rem
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1400px
        }
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0
    }

    .pointer-events-none {
        pointer-events: none
    }

    .collapse {
        visibility: collapse
    }

    .fixed {
        position: fixed
    }

    .absolute {
        position: absolute
    }

    .relative {
        position: relative
    }

    .sticky {
        position: sticky
    }

    .inset-0 {
        inset: 0
    }

    .inset-x-0 {
        left: 0;
        right: 0
    }

    .inset-y-0 {
        top: 0;
        bottom: 0
    }

    .bottom-0 {
        bottom: 0
    }

    .bottom-2 {
        bottom: .5rem
    }

    .left-0 {
        left: 0
    }

    .left-1\/2 {
        left: 50%
    }

    .left-2 {
        left: .5rem
    }

    .left-\[50\%\] {
        left: 50%
    }

    .right-0 {
        right: 0
    }

    .right-4 {
        right: 1rem
    }

    .top-0 {
        top: 0
    }

    .top-1\/2 {
        top: 50%
    }

    .top-4 {
        top: 1rem
    }

    .top-\[50\%\] {
        top: 50%
    }

    .z-10 {
        z-index: 10
    }

    .z-20 {
        z-index: 20
    }

    .z-50 {
        z-index: 50
    }

    .z-\[1000000000\] {
        z-index: 1000000000
    }

    .col-span-2 {
        grid-column: span 2/span 2
    }

    .col-span-3 {
        grid-column: span 3/span 3
    }

    .col-start-1 {
        grid-column-start: 1
    }

    .col-start-2 {
        grid-column-start: 2
    }

    .col-start-3 {
        grid-column-start: 3
    }

    .col-start-4 {
        grid-column-start: 4
    }

    .col-start-5 {
        grid-column-start: 5
    }

    .row-span-2 {
        grid-row: span 2/span 2
    }

    .row-span-4 {
        grid-row: span 4/span 4
    }

    .row-start-10 {
        grid-row-start: 10
    }

    .row-start-11 {
        grid-row-start: 11
    }

    .row-start-12 {
        grid-row-start: 12
    }

    .row-start-13 {
        grid-row-start: 13
    }

    .row-start-2 {
        grid-row-start: 2
    }

    .row-start-3 {
        grid-row-start: 3
    }

    .row-start-4 {
        grid-row-start: 4
    }

    .row-start-5 {
        grid-row-start: 5
    }

    .row-start-6 {
        grid-row-start: 6
    }

    .row-start-7 {
        grid-row-start: 7
    }

    .row-start-8 {
        grid-row-start: 8
    }

    .row-start-9 {
        grid-row-start: 9
    }

    .row-start-\[14\] {
        grid-row-start: 14
    }

    .-mx-1 {
        margin-left: -.25rem;
        margin-right: -.25rem
    }

    .mx-3 {
        margin-left: .75rem;
        margin-right: .75rem
    }

    .mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .my-1 {
        margin-top: .25rem;
        margin-bottom: .25rem
    }

    .mb-4 {
        margin-bottom: 1rem
    }

    .ml-auto {
        margin-left: auto
    }

    .mr-2 {
        margin-right: .5rem
    }

    .mt-2 {
        margin-top: .5rem
    }

    .mt-4 {
        margin-top: 1rem
    }

    .mt-5 {
        margin-top: 1.25rem
    }

    .mt-\[2px\] {
        margin-top: 2px
    }

    .mt-auto {
        margin-top: auto
    }

    .block {
        display: block
    }

    .inline-block {
        display: inline-block
    }

    .inline {
        display: inline
    }

    .flex {
        display: flex
    }

    .inline-flex {
        display: inline-flex
    }

    .table {
        display: table
    }

    .grid {
        display: grid
    }

    .hidden {
        display: none
    }

    .aspect-square {
        aspect-ratio: 1/1
    }

    .h-0 {
        height: 0
    }

    .h-0\.5 {
        height: .125rem
    }

    .h-10 {
        height: 2.5rem
    }

    .h-11 {
        height: 2.75rem
    }

    .h-12 {
        height: 3rem
    }

    .h-14 {
        height: 3.5rem
    }

    .h-2 {
        height: .5rem
    }

    .h-2\.5 {
        height: .625rem
    }

    .h-20 {
        height: 5rem
    }

    .h-3 {
        height: .75rem
    }

    .h-3\.5 {
        height: .875rem
    }

    .h-4 {
        height: 1rem
    }

    .h-5 {
        height: 1.25rem
    }

    .h-6 {
        height: 1.5rem
    }

    .h-7 {
        height: 1.75rem
    }

    .h-8 {
        height: 2rem
    }

    .h-9 {
        height: 2.25rem
    }

    .h-\[1\.2rem\] {
        height: 1.2rem
    }

    .h-\[10px\] {
        height: 10px
    }

    .h-\[12\.5px\] {
        height: 12.5px
    }

    .h-\[140px\] {
        height: 140px
    }

    .h-\[15px\] {
        height: 15px
    }

    .h-\[1px\] {
        height: 1px
    }

    .h-\[400px\] {
        height: 400px
    }

    .h-\[7\.5px\] {
        height: 7.5px
    }

    .h-\[var\(--radix-select-trigger-height\)\] {
        height: var(--radix-select-trigger-height)
    }

    .h-fit {
        height: -moz-fit-content;
        height: fit-content
    }

    .h-full {
        height: 100%
    }

    .h-px {
        height: 1px
    }

    .h-screen {
        height: 100vh
    }

    .max-h-96 {
        max-height: 24rem
    }

    .min-h-6 {
        min-height: 1.5rem
    }

    .min-h-\[80px\] {
        min-height: 80px
    }

    .min-h-screen {
        min-height: 100vh
    }

    .w-0 {
        width: 0
    }

    .w-0\.5 {
        width: .125rem
    }

    .w-10 {
        width: 2.5rem
    }

    .w-11 {
        width: 2.75rem
    }

    .w-12 {
        width: 3rem
    }

    .w-14 {
        width: 3.5rem
    }

    .w-2 {
        width: .5rem
    }

    .w-2\.5 {
        width: .625rem
    }

    .w-20 {
        width: 5rem
    }

    .w-28 {
        width: 7rem
    }

    .w-3 {
        width: .75rem
    }

    .w-3\.5 {
        width: .875rem
    }

    .w-3\/4 {
        width: 75%
    }

    .w-32 {
        width: 8rem
    }

    .w-4 {
        width: 1rem
    }

    .w-5 {
        width: 1.25rem
    }

    .w-56 {
        width: 14rem
    }

    .w-6 {
        width: 1.5rem
    }

    .w-7 {
        width: 1.75rem
    }

    .w-72 {
        width: 18rem
    }

    .w-8 {
        width: 2rem
    }

    .w-9 {
        width: 2.25rem
    }

    .w-\[1\.2rem\] {
        width: 1.2rem
    }

    .w-\[10px\] {
        width: 10px
    }

    .w-\[12\.5px\] {
        width: 12.5px
    }

    .w-\[140px\] {
        width: 140px
    }

    .w-\[15px\] {
        width: 15px
    }

    .w-\[16\%\] {
        width: 16%
    }

    .w-\[18\%\] {
        width: 18%
    }

    .w-\[1px\] {
        width: 1px
    }

    .w-\[200px\] {
        width: 200px
    }

    .w-\[250px\] {
        width: 250px
    }

    .w-\[280px\] {
        width: 280px
    }

    .w-\[32\%\] {
        width: 32%
    }

    .w-\[34\%\] {
        width: 34%
    }

    .w-\[66px\] {
        width: 66px
    }

    .w-\[7\.5px\] {
        width: 7.5px
    }

    .w-fit {
        width: -moz-fit-content;
        width: fit-content
    }

    .w-full {
        width: 100%
    }

    .min-w-48 {
        min-width: 12rem
    }

    .min-w-6 {
        min-width: 1.5rem
    }

    .min-w-\[8rem\] {
        min-width: 8rem
    }

    .min-w-\[var\(--radix-select-trigger-width\)\] {
        min-width: var(--radix-select-trigger-width)
    }

    .max-w-28 {
        max-width: 7rem
    }

    .max-w-2xl {
        max-width: 42rem
    }

    .max-w-52 {
        max-width: 13rem
    }

    .max-w-80 {
        max-width: 20rem
    }

    .max-w-lg {
        max-width: 32rem
    }

    .max-w-md {
        max-width: 28rem
    }

    .flex-1 {
        flex: 1 1 0%
    }

    .shrink-0 {
        flex-shrink: 0
    }

    .caption-bottom {
        caption-side: bottom
    }

    .border-collapse {
        border-collapse: collapse
    }

    .translate-x-\[-50\%\] {
        --tw-translate-x: -50%
    }

    .translate-x-\[-50\%\],
    .translate-y-\[-50\%\] {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .translate-y-\[-50\%\] {
        --tw-translate-y: -50%
    }

    .rotate-0 {
        --tw-rotate: 0deg
    }

    .rotate-0,
    .rotate-180 {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .rotate-180 {
        --tw-rotate: 180deg
    }

    .rotate-90 {
        --tw-rotate: 90deg
    }

    .rotate-90,
    .rotate-\[215deg\] {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .rotate-\[215deg\] {
        --tw-rotate: 215deg
    }

    .scale-0 {
        --tw-scale-x: 0;
        --tw-scale-y: 0
    }

    .scale-0,
    .scale-100 {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1
    }

    .transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    @keyframes meteor {
        0% {
            transform: rotate(215deg) translateX(0);
            opacity: 1
        }

        70% {
            opacity: 1
        }

        to {
            transform: rotate(215deg) translateX(-500px);
            opacity: 0
        }
    }

    .animate-meteor-effect {
        animation: meteor 5s linear infinite
    }

    @keyframes pulse {
        50% {
            opacity: .5
        }
    }

    .animate-pulse {
        animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
    }

    @keyframes spin {
        to {
            transform: rotate(1turn)
        }
    }

    .animate-spin {
        animation: spin 1s linear infinite
    }

    .cursor-default {
        cursor: default
    }

    .cursor-pointer {
        cursor: pointer
    }

    .select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none
    }

    .grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr))
    }

    .grid-rows-\[repeat\(14\2c 1fr\)\] {
        grid-template-rows: repeat(14, 1fr)
    }

    .flex-row {
        flex-direction: row
    }

    .flex-col {
        flex-direction: column
    }

    .flex-col-reverse {
        flex-direction: column-reverse
    }

    .flex-wrap {
        flex-wrap: wrap
    }

    .place-items-center {
        place-items: center
    }

    .items-start {
        align-items: flex-start
    }

    .items-center {
        align-items: center
    }

    .justify-start {
        justify-content: flex-start
    }

    .justify-center {
        justify-content: center
    }

    .justify-between {
        justify-content: space-between
    }

    .gap-1 {
        gap: .25rem
    }

    .gap-2 {
        gap: .5rem
    }

    .gap-4 {
        gap: 1rem
    }

    .gap-5 {
        gap: 1.25rem
    }

    .gap-8 {
        gap: 2rem
    }

    .gap-x-1 {
        -moz-column-gap: .25rem;
        column-gap: .25rem
    }

    .gap-y-4 {
        row-gap: 1rem
    }

    .space-y-0>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }

    .space-y-0\.5>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(.125rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(.125rem * var(--tw-space-y-reverse))
    }

    .space-y-1>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(.25rem * var(--tw-space-y-reverse))
    }

    .space-y-1\.5>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(.375rem * var(--tw-space-y-reverse))
    }

    .space-y-2>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
    }

    .space-y-4>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1rem * var(--tw-space-y-reverse))
    }

    .space-y-5>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
    }

    .space-y-6>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
    }

    .space-y-8>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem * var(--tw-space-y-reverse))
    }

    .overflow-auto {
        overflow: auto
    }

    .overflow-hidden {
        overflow: hidden
    }

    .overflow-x-auto {
        overflow-x: auto
    }

    .overflow-y-auto {
        overflow-y: auto
    }

    .overflow-x-hidden {
        overflow-x: hidden
    }

    .whitespace-nowrap {
        white-space: nowrap
    }

    .rounded {
        border-radius: .25rem
    }

    .rounded-\[9999px\],
    .rounded-full {
        border-radius: 9999px
    }

    .rounded-lg {
        border-radius: var(--radius)
    }

    .rounded-md {
        border-radius: calc(var(--radius) - 2px)
    }

    .rounded-sm {
        border-radius: calc(var(--radius) - 4px)
    }

    .rounded-xl {
        border-radius: .75rem
    }

    .border {
        border-width: 1px
    }

    .border-0 {
        border-width: 0
    }

    .border-2 {
        border-width: 2px
    }

    .border-\[3px\] {
        border-width: 3px
    }

    .border-b {
        border-bottom-width: 1px
    }

    .border-l {
        border-left-width: 1px
    }

    .border-r {
        border-right-width: 1px
    }

    .border-t {
        border-top-width: 1px
    }

    .border-t-0 {
        border-top-width: 0
    }

    .border-\[\#18181b\] {
        --tw-border-opacity: 1;
        border-color: rgb(24 24 27/var(--tw-border-opacity))
    }

    .border-background {
        border-color: hsl(var(--background))
    }

    .border-border {
        border-color: hsl(var(--border))
    }

    .border-input {
        border-color: hsl(var(--input))
    }

    .border-poly-r-black {
        border-color: hsl(var(--poly-roulette-black))
    }

    .border-poly-r-green {
        border-color: hsl(var(--poly-roulette-green))
    }

    .border-poly-r-red {
        border-color: hsl(var(--poly-roulette-red))
    }

    .border-poly-toast-lost-border {
        border-color: hsl(var(--poly-toast-lost-border))
    }

    .border-poly-toast-won-border {
        border-color: hsl(var(--poly-toast-won-border))
    }

    .border-poly-toast-won-text {
        border-color: hsl(var(--poly-toast-won-text))
    }

    .border-primary {
        border-color: hsl(var(--primary))
    }

    .border-slate-800 {
        --tw-border-opacity: 1;
        border-color: rgb(30 41 59/var(--tw-border-opacity))
    }

    .border-transparent {
        border-color: transparent
    }

    .bg-\[\#18181b\] {
        --tw-bg-opacity: 1;
        background-color: rgb(24 24 27/var(--tw-bg-opacity))
    }

    .bg-background {
        background-color: hsl(var(--background))
    }

    .bg-background\/95 {
        background-color: hsl(var(--background)/.95)
    }

    .bg-black\/80 {
        background-color: rgba(0, 0, 0, .8)
    }

    .bg-border {
        background-color: hsl(var(--border))
    }

    .bg-destructive {
        background-color: hsl(var(--destructive))
    }

    .bg-muted {
        background-color: hsl(var(--muted))
    }

    .bg-muted\/50 {
        background-color: hsl(var(--muted)/.5)
    }

    .bg-poly-green {
        background-color: hsl(var(--poly-green))
    }

    .bg-poly-r-black {
        background-color: hsl(var(--poly-roulette-black))
    }

    .bg-poly-r-green {
        background-color: hsl(var(--poly-roulette-green));
    }

    .bg-poly-r-red {
        background-color: hsl(var(--poly-roulette-red))
    }

    .bg-poly-red-light {
        background-color: hsl(var(--poly-red-light))
    }

    .bg-poly-toast-lost-bg {
        background-color: hsl(var(--poly-toast-lost-bg))
    }

    .bg-poly-toast-won-border {
        background-color: hsl(var(--poly-toast-won-border))
    }

    .bg-poly-toast-won-text {
        background-color: hsl(var(--poly-toast-won-text))
    }

    .bg-popover {
        background-color: hsl(var(--popover))
    }

    .bg-primary {
        background-color: hsl(var(--primary))
    }

    .bg-secondary {
        background-color: hsl(var(--secondary))
    }

    .bg-slate-500 {
        --tw-bg-opacity: 1;
        background-color: rgb(100 116 139/var(--tw-bg-opacity))
    }

    .bg-slate-900\/\[0\.8\] {
        background-color: rgba(15, 23, 42, .8)
    }

    .bg-transparent {
        background-color: transparent
    }

    .bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255/var(--tw-bg-opacity))
    }

    .bg-\[radial-gradient\(hsl\(var\(--poly-green\)\)_40\%\2c transparent_60\%\)\] {
        background-image: radial-gradient(hsl(var(--poly-green)) 40%, transparent 60%)
    }

    .fill-current {
        fill: currentColor
    }

    .fill-foreground {
        fill: hsl(var(--foreground))
    }

    .stroke-foreground {
        stroke: hsl(var(--foreground))
    }

    .p-0 {
        padding: 0
    }

    .p-1 {
        padding: .25rem
    }

    .p-2 {
        padding: .5rem
    }

    .p-4 {
        padding: 1rem
    }

    .p-5 {
        padding: 1.25rem
    }

    .p-6 {
        padding: 1.5rem
    }

    .p-\[1px\] {
        padding: 1px
    }

    .p-\[2px\] {
        padding: 2px
    }

    .px-1 {
        padding-left: .25rem;
        padding-right: .25rem
    }

    .px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .px-2\.5 {
        padding-left: .625rem;
        padding-right: .625rem
    }

    .px-3 {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .px-4 {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }

    .px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .py-1 {
        padding-top: .25rem;
        padding-bottom: .25rem
    }

    .py-1\.5 {
        padding-top: .375rem;
        padding-bottom: .375rem
    }

    .py-2 {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem
    }

    .pb-6 {
        padding-bottom: 1.5rem
    }

    .pl-8 {
        padding-left: 2rem
    }

    .pr-2 {
        padding-right: .5rem
    }

    .pt-12 {
        padding-top: 3rem
    }

    .pt-2 {
        padding-top: .5rem
    }

    .pt-8 {
        padding-top: 2rem
    }

    .pt-\[73px\] {
        padding-top: 73px
    }

    .text-left {
        text-align: left
    }

    .text-center {
        text-align: center
    }

    .align-middle {
        vertical-align: middle
    }

    .font-sans {
        font-family: var(--font-sf)
    }

    .text-2xl {
        font-size: 1.5rem;
        line-height: 2rem
    }

    .text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }

    .text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem
    }

    .text-\[0\.625rem\] {
        font-size: .625rem
    }

    .text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem
    }

    .text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem
    }

    .text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .font-medium {
        font-weight: 500
    }

    .font-semibold {
        font-weight: 600
    }

    .leading-none {
        line-height: 1
    }

    .leading-tight {
        line-height: 1.25
    }

    .tracking-tight {
        letter-spacing: -.025em
    }

    .tracking-widest {
        letter-spacing: .1em
    }

    .text-background {
        color: hsl(var(--background))
    }

    .text-current {
        color: currentColor
    }

    .text-destructive {
        color: hsl(var(--destructive))
    }

    .text-destructive-foreground {
        color: hsl(var(--destructive-foreground))
    }

    .text-foreground {
        color: hsl(var(--foreground))
    }

    .text-muted-foreground {
        color: hsl(var(--muted-foreground))
    }

    .text-poly-blue {
        color: hsl(var(--poly-blue))
    }

    .text-poly-green {
        color: hsl(var(--poly-green))
    }

    .text-poly-orange {
        color: hsl(var(--poly-orange))
    }

    .text-poly-red {
        color: hsl(var(--poly-red))
    }

    .text-poly-toast-lost-text {
        color: hsl(var(--poly-toast-lost-text))
    }

    .text-poly-toast-won-text {
        color: hsl(var(--poly-toast-won-text))
    }

    .text-poly-yellow {
        color: hsl(var(--poly-yellow))
    }

    .text-popover-foreground {
        color: hsl(var(--popover-foreground))
    }

    .text-primary {
        color: hsl(var(--primary))
    }

    .text-primary-foreground {
        color: hsl(var(--primary-foreground))
    }

    .text-secondary-foreground {
        color: hsl(var(--secondary-foreground))
    }

    .text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255/var(--tw-text-opacity))
    }

    .underline-offset-4 {
        text-underline-offset: 4px
    }

    .antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }

    .opacity-0 {
        opacity: 0
    }

    .opacity-100 {
        opacity: 1
    }

    .opacity-50 {
        opacity: .5
    }

    .opacity-60 {
        opacity: .6
    }

    .opacity-70 {
        opacity: .7
    }

    .opacity-\[0\.8\] {
        opacity: .8
    }

    .shadow-\[0_0_0_1px_\#ffffff10\] {
        --tw-shadow: 0 0 0 1px #ffffff10;
        --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color)
    }

    .shadow-\[0_0_0_1px_\#ffffff10\],
    .shadow-\[0_0_5px_4px_orangered\] {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .shadow-\[0_0_5px_4px_orangered\] {
        --tw-shadow: 0 0 5px 4px #ff4500;
        --tw-shadow-colored: 0 0 5px 4px var(--tw-shadow-color)
    }

    .shadow-\[0_0_9px_4px_gold\] {
        --tw-shadow: 0 0 9px 4px gold;
        --tw-shadow-colored: 0 0 9px 4px var(--tw-shadow-color)
    }

    .shadow-\[0_0_9px_4px_gold\],
    .shadow-\[0_0_9px_4px_orange\] {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .shadow-\[0_0_9px_4px_orange\] {
        --tw-shadow: 0 0 9px 4px orange;
        --tw-shadow-colored: 0 0 9px 4px var(--tw-shadow-color)
    }

    .shadow-\[0_0_9px_4px_white\] {
        --tw-shadow: 0 0 9px 4px #fff;
        --tw-shadow-colored: 0 0 9px 4px var(--tw-shadow-color)
    }

    .shadow-\[0_0_9px_4px_white\],
    .shadow-lg {
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .shadow-lg {
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
    }

    .shadow-md {
        --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .outline-none {
        outline: 2px solid transparent;
        outline-offset: 2px
    }

    .outline {
        outline-style: solid
    }

    .ring-0 {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
    }

    .ring-offset-background {
        --tw-ring-offset-color: hsl(var(--background))
    }

    .filter {
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
    }

    .backdrop-blur {
        --tw-backdrop-blur: blur(8px)
    }

    .backdrop-blur,
    .backdrop-blur-xl {
        -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
        backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
    }

    .backdrop-blur-xl {
        --tw-backdrop-blur: blur(24px)
    }

    .transition {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .transition-all {
        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .transition-colors {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .transition-opacity {
        transition-property: opacity;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .transition-transform {
        transition-property: transform;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s
    }

    .duration-100 {
        transition-duration: .1s
    }

    .duration-200 {
        transition-duration: .2s
    }

    .duration-500 {
        transition-duration: .5s
    }

    .ease-in-out {
        transition-timing-function: cubic-bezier(.4, 0, .2, 1)
    }

    .ease-out {
        transition-timing-function: cubic-bezier(0, 0, .2, 1)
    }

    @keyframes enter {
        0% {
            opacity: var(--tw-enter-opacity, 1);
            transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))
        }
    }

    @keyframes exit {
        to {
            opacity: var(--tw-exit-opacity, 1);
            transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))
        }
    }

    .animate-in {
        animation-name: enter;
        animation-duration: .15s;
        --tw-enter-opacity: initial;
        --tw-enter-scale: initial;
        --tw-enter-rotate: initial;
        --tw-enter-translate-x: initial;
        --tw-enter-translate-y: initial
    }

    .fade-in-0 {
        --tw-enter-opacity: 0
    }

    .zoom-in-95 {
        --tw-enter-scale: .95
    }

    .duration-100 {
        animation-duration: .1s
    }

    .duration-200 {
        animation-duration: .2s
    }

    .duration-500 {
        animation-duration: .5s
    }

    .ease-in-out {
        animation-timing-function: cubic-bezier(.4, 0, .2, 1)
    }

    .ease-out {
        animation-timing-function: cubic-bezier(0, 0, .2, 1)
    }

    .paused {
        animation-play-state: paused
    }

    .\[writing-mode\:horizontal-tb\] {
        writing-mode: horizontal-tb
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0
    }

    input[type=number] {
        -moz-appearance: textfield
    }

    section[tabindex="-1"]:has(>.toaster.group .notification__toast) {
        position: relative;
        z-index: 10000000000
    }

    .hide-scroll-bar::-webkit-scrollbar {
        display: none
    }

    .file\:border-0::file-selector-button {
        border-width: 0
    }

    .file\:bg-transparent::file-selector-button {
        background-color: transparent
    }

    .file\:text-sm::file-selector-button {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .file\:font-medium::file-selector-button {
        font-weight: 500
    }

    .placeholder\:text-muted-foreground::-moz-placeholder {
        color: hsl(var(--muted-foreground))
    }

    .placeholder\:text-muted-foreground::placeholder {
        color: hsl(var(--muted-foreground))
    }

    .before\:absolute:before {
        content: var(--tw-content);
        position: absolute
    }

    .before\:top-1\/2:before {
        content: var(--tw-content);
        top: 50%
    }

    .before\:h-\[1px\]:before {
        content: var(--tw-content);
        height: 1px
    }

    .before\:w-\[50px\]:before {
        content: var(--tw-content);
        width: 50px
    }

    .before\:-translate-y-\[50\%\]:before {
        --tw-translate-y: -50%
    }

    .before\:-translate-y-\[50\%\]:before,
    .before\:transform:before {
        content: var(--tw-content);
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .before\:bg-gradient-to-r:before {
        content: var(--tw-content);
        background-image: linear-gradient(to right, var(--tw-gradient-stops))
    }

    .before\:from-\[\#64748b\]:before {
        content: var(--tw-content);
        --tw-gradient-from: #64748b var(--tw-gradient-from-position);
        --tw-gradient-to: rgba(100, 116, 139, 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
    }

    .before\:to-transparent:before {
        content: var(--tw-content);
        --tw-gradient-to: transparent var(--tw-gradient-to-position)
    }

    .before\:content-\[\'\'\]:before {
        --tw-content: "";
        content: var(--tw-content)
    }

    .hover\:border-poly-r-selected:hover {
        border-color: hsl(var(--poly-roulette-selected))
    }

    .hover\:bg-accent:hover {
        background-color: hsl(var(--accent))
    }

    .hover\:bg-border:hover {
        background-color: hsl(var(--border))
    }

    .hover\:bg-destructive\/90:hover {
        background-color: hsl(var(--destructive)/.9)
    }

    .hover\:bg-muted:hover {
        background-color: hsl(var(--muted))
    }

    .hover\:bg-muted\/50:hover {
        background-color: hsl(var(--muted)/.5)
    }

    .hover\:bg-poly-r-black:hover {
        background-color: hsl(var(--poly-roulette-black))
    }

    .hover\:bg-poly-r-green:hover {
        background-color: hsl(var(--poly-roulette-green))
    }

    .hover\:bg-poly-r-red:hover {
        background-color: hsl(var(--poly-roulette-red))
    }

    .hover\:bg-poly-red:hover {
        background-color: hsl(var(--poly-red))
    }

    .hover\:bg-poly-red-light:hover {
        background-color: hsl(var(--poly-red-light))
    }

    .hover\:bg-primary\/90:hover {
        background-color: hsl(var(--primary)/.9)
    }

    .hover\:bg-secondary\/80:hover {
        background-color: hsl(var(--secondary)/.8)
    }

    .hover\:bg-transparent:hover {
        background-color: transparent
    }

    .hover\:text-accent-foreground:hover {
        color: hsl(var(--accent-foreground))
    }

    .hover\:text-muted-foreground:hover {
        color: hsl(var(--muted-foreground))
    }

    .hover\:text-poly-blue:hover {
        color: hsl(var(--poly-blue))
    }

    .hover\:text-poly-orange:hover {
        color: hsl(var(--poly-orange))
    }

    .hover\:text-poly-r-selected:hover {
        color: hsl(var(--poly-roulette-selected))
    }

    .hover\:text-poly-red:hover {
        color: hsl(var(--poly-red))
    }

    .hover\:text-poly-yellow:hover {
        color: hsl(var(--poly-yellow))
    }

    .hover\:underline:hover {
        text-decoration-line: underline
    }

    .hover\:opacity-100:hover {
        opacity: 1
    }

    .focus\:bg-accent:focus {
        background-color: hsl(var(--accent))
    }

    .focus\:text-accent-foreground:focus {
        color: hsl(var(--accent-foreground))
    }

    .focus\:outline-none:focus {
        outline: 2px solid transparent;
        outline-offset: 2px
    }

    .focus\:ring-2:focus {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
    }

    .focus\:ring-ring:focus {
        --tw-ring-color: hsl(var(--ring))
    }

    .focus\:ring-offset-2:focus {
        --tw-ring-offset-width: 2px
    }

    .focus-visible\:outline-none:focus-visible {
        outline: 2px solid transparent;
        outline-offset: 2px
    }

    .focus-visible\:ring-2:focus-visible {
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
    }

    .focus-visible\:ring-ring:focus-visible {
        --tw-ring-color: hsl(var(--ring))
    }

    .focus-visible\:ring-offset-2:focus-visible {
        --tw-ring-offset-width: 2px
    }

    .focus-visible\:ring-offset-background:focus-visible {
        --tw-ring-offset-color: hsl(var(--background))
    }

    .disabled\:pointer-events-none:disabled {
        pointer-events: none
    }

    .disabled\:cursor-not-allowed:disabled {
        cursor: not-allowed
    }

    .disabled\:opacity-100:disabled {
        opacity: 1
    }

    .disabled\:opacity-50:disabled {
        opacity: .5
    }

    .group[data-collapsed=true] .group-\[\[data-collapsed\=true\]\]\:justify-center {
        justify-content: center
    }

    .group.toaster .group-\[\.toaster\]\:border-border {
        border-color: hsl(var(--border))
    }

    .group.toast .group-\[\.toast\]\:bg-muted {
        background-color: hsl(var(--muted))
    }

    .group.toast .group-\[\.toast\]\:bg-primary {
        background-color: hsl(var(--primary))
    }

    .group.toaster .group-\[\.toaster\]\:bg-background {
        background-color: hsl(var(--background))
    }

    .group[data-collapsed=true] .group-\[\[data-collapsed\=true\]\]\:px-2 {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .group.toast .group-\[\.toast\]\:text-muted-foreground {
        color: hsl(var(--muted-foreground))
    }

    .group.toast .group-\[\.toast\]\:text-primary-foreground {
        color: hsl(var(--primary-foreground))
    }

    .group.toaster .group-\[\.toaster\]\:text-foreground {
        color: hsl(var(--foreground))
    }

    .group.toaster .group-\[\.toaster\]\:shadow-lg {
        --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .peer:disabled~.peer-disabled\:cursor-not-allowed {
        cursor: not-allowed
    }

    .peer:disabled~.peer-disabled\:opacity-70 {
        opacity: .7
    }

    .data-\[disabled\]\:pointer-events-none[data-disabled] {
        pointer-events: none
    }

    .data-\[side\=bottom\]\:translate-y-1[data-side=bottom] {
        --tw-translate-y: 0.25rem
    }

    .data-\[side\=bottom\]\:translate-y-1[data-side=bottom],
    .data-\[side\=left\]\:-translate-x-1[data-side=left] {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .data-\[side\=left\]\:-translate-x-1[data-side=left] {
        --tw-translate-x: -0.25rem
    }

    .data-\[side\=right\]\:translate-x-1[data-side=right] {
        --tw-translate-x: 0.25rem
    }

    .data-\[side\=right\]\:translate-x-1[data-side=right],
    .data-\[side\=top\]\:-translate-y-1[data-side=top] {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .data-\[side\=top\]\:-translate-y-1[data-side=top] {
        --tw-translate-y: -0.25rem
    }

    .data-\[state\=checked\]\:translate-x-5[data-state=checked] {
        --tw-translate-x: 1.25rem
    }

    .data-\[state\=checked\]\:translate-x-5[data-state=checked],
    .data-\[state\=unchecked\]\:translate-x-0[data-state=unchecked] {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .data-\[state\=unchecked\]\:translate-x-0[data-state=unchecked] {
        --tw-translate-x: 0px
    }

    .data-\[state\=on\]\:border-poly-r-selected[data-state=on] {
        border-color: hsl(var(--poly-roulette-selected))
    }

    .data-\[state\=active\]\:bg-background[data-state=active] {
        background-color: hsl(var(--background))
    }

    .data-\[state\=checked\]\:bg-primary[data-state=checked] {
        background-color: hsl(var(--primary))
    }

    .data-\[state\=on\]\:bg-accent[data-state=on] {
        background-color: hsl(var(--accent))
    }

    .data-\[state\=on\]\:bg-poly-r-black[data-state=on] {
        background-color: hsl(var(--poly-roulette-black))
    }

    .data-\[state\=on\]\:bg-poly-r-green[data-state=on] {
        background-color: hsl(var(--poly-roulette-green))
    }

    .data-\[state\=on\]\:bg-poly-r-red[data-state=on] {
        background-color: hsl(var(--poly-roulette-red))
    }

    .data-\[state\=on\]\:bg-transparent[data-state=on] {
        background-color: transparent
    }

    .data-\[state\=open\]\:bg-accent[data-state=open] {
        background-color: hsl(var(--accent))
    }

    .data-\[state\=open\]\:bg-secondary[data-state=open] {
        background-color: hsl(var(--secondary))
    }

    .data-\[state\=selected\]\:bg-muted[data-state=selected] {
        background-color: hsl(var(--muted))
    }

    .data-\[state\=unchecked\]\:bg-input[data-state=unchecked] {
        background-color: hsl(var(--input))
    }

    .data-\[collapsed\=true\]\:py-4[data-collapsed=true] {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .data-\[selected\=true\]\:text-poly-blue-light[data-selected=true] {
        color: hsl(var(--poly-blue-light))
    }

    .data-\[selected\=true\]\:text-poly-orange-light[data-selected=true] {
        color: hsl(var(--poly-orange-light))
    }

    .data-\[selected\=true\]\:text-poly-red-light[data-selected=true] {
        color: hsl(var(--poly-red-light))
    }

    .data-\[selected\=true\]\:text-poly-yellow-light[data-selected=true] {
        color: hsl(var(--poly-yellow-light))
    }

    .data-\[state\=active\]\:text-foreground[data-state=active] {
        color: hsl(var(--foreground))
    }

    .data-\[state\=on\]\:text-accent-foreground[data-state=on] {
        color: hsl(var(--accent-foreground))
    }

    .data-\[state\=on\]\:text-poly-r-selected[data-state=on] {
        color: hsl(var(--poly-roulette-selected))
    }

    .data-\[state\=open\]\:text-muted-foreground[data-state=open] {
        color: hsl(var(--muted-foreground))
    }

    .data-\[disabled\]\:opacity-50[data-disabled] {
        opacity: .5
    }

    .data-\[state\=active\]\:shadow-sm[data-state=active] {
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
        --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .data-\[state\=on\]\:shadow-\[inset_0_0_0_2000px_hsl\(var\(--poly-roulette-shadow-selected\)\)\][data-state=on] {
        --tw-shadow: inset 0 0 0 2000px hsl(var(--poly-roulette-shadow-selected));
        --tw-shadow-colored: inset 0 0 0 2000px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }

    .data-\[state\=closed\]\:duration-300[data-state=closed] {
        transition-duration: .3s
    }

    .data-\[state\=open\]\:duration-500[data-state=open] {
        transition-duration: .5s
    }

    .data-\[state\=open\]\:animate-in[data-state=open] {
        animation-name: enter;
        animation-duration: .15s;
        --tw-enter-opacity: initial;
        --tw-enter-scale: initial;
        --tw-enter-rotate: initial;
        --tw-enter-translate-x: initial;
        --tw-enter-translate-y: initial
    }

    .data-\[state\=closed\]\:animate-out[data-state=closed] {
        animation-name: exit;
        animation-duration: .15s;
        --tw-exit-opacity: initial;
        --tw-exit-scale: initial;
        --tw-exit-rotate: initial;
        --tw-exit-translate-x: initial;
        --tw-exit-translate-y: initial
    }

    .data-\[state\=closed\]\:fade-out-0[data-state=closed] {
        --tw-exit-opacity: 0
    }

    .data-\[state\=open\]\:fade-in-0[data-state=open] {
        --tw-enter-opacity: 0
    }

    .data-\[state\=closed\]\:zoom-out-95[data-state=closed] {
        --tw-exit-scale: .95
    }

    .data-\[state\=open\]\:zoom-in-95[data-state=open] {
        --tw-enter-scale: .95
    }

    .data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom] {
        --tw-enter-translate-y: -0.5rem
    }

    .data-\[side\=left\]\:slide-in-from-right-2[data-side=left] {
        --tw-enter-translate-x: 0.5rem
    }

    .data-\[side\=right\]\:slide-in-from-left-2[data-side=right] {
        --tw-enter-translate-x: -0.5rem
    }

    .data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top] {
        --tw-enter-translate-y: 0.5rem
    }

    .data-\[state\=closed\]\:slide-out-to-bottom[data-state=closed] {
        --tw-exit-translate-y: 100%
    }

    .data-\[state\=closed\]\:slide-out-to-left[data-state=closed] {
        --tw-exit-translate-x: -100%
    }

    .data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state=closed] {
        --tw-exit-translate-x: -50%
    }

    .data-\[state\=closed\]\:slide-out-to-right[data-state=closed] {
        --tw-exit-translate-x: 100%
    }

    .data-\[state\=closed\]\:slide-out-to-top[data-state=closed] {
        --tw-exit-translate-y: -100%
    }

    .data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state=closed] {
        --tw-exit-translate-y: -48%
    }

    .data-\[state\=open\]\:slide-in-from-bottom[data-state=open] {
        --tw-enter-translate-y: 100%
    }

    .data-\[state\=open\]\:slide-in-from-left[data-state=open] {
        --tw-enter-translate-x: -100%
    }

    .data-\[state\=open\]\:slide-in-from-left-1\/2[data-state=open] {
        --tw-enter-translate-x: -50%
    }

    .data-\[state\=open\]\:slide-in-from-right[data-state=open] {
        --tw-enter-translate-x: 100%
    }

    .data-\[state\=open\]\:slide-in-from-top[data-state=open] {
        --tw-enter-translate-y: -100%
    }

    .data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state=open] {
        --tw-enter-translate-y: -48%
    }

    .data-\[state\=closed\]\:duration-300[data-state=closed] {
        animation-duration: .3s
    }

    .data-\[state\=open\]\:duration-500[data-state=open] {
        animation-duration: .5s
    }

    .dark\:-rotate-90:is(.dark *) {
        --tw-rotate: -90deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .dark\:rotate-0:is(.dark *) {
        --tw-rotate: 0deg;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .dark\:scale-0:is(.dark *) {
        --tw-scale-x: 0;
        --tw-scale-y: 0;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .dark\:scale-100:is(.dark *) {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .dark\:border-none:is(.dark *) {
        border-style: none
    }

    .dark\:border-border:is(.dark *) {
        border-color: hsl(var(--border))
    }

    .dark\:bg-muted:is(.dark *) {
        background-color: hsl(var(--muted))
    }

    .dark\:bg-none:is(.dark *) {
        background-image: none
    }

    .dark\:text-foreground:is(.dark *) {
        color: hsl(var(--foreground))
    }

    .dark\:text-muted-foreground:is(.dark *) {
        color: hsl(var(--muted-foreground))
    }

    .dark\:text-white:is(.dark *) {
        --tw-text-opacity: 1;
        color: rgb(255 255 255/var(--tw-text-opacity))
    }

    .dark\:hover\:border-poly-r-selected:hover:is(.dark *) {
        border-color: hsl(var(--poly-roulette-selected))
    }

    .dark\:hover\:bg-border:hover:is(.dark *) {
        background-color: hsl(var(--border))
    }

    .dark\:hover\:bg-muted:hover:is(.dark *) {
        background-color: hsl(var(--muted))
    }

    .dark\:hover\:text-white:hover:is(.dark *) {
        --tw-text-opacity: 1;
        color: rgb(255 255 255/var(--tw-text-opacity))
    }

    .dark\:data-\[state\=on\]\:border-poly-r-selected[data-state=on]:is(.dark *) {
        border-color: hsl(var(--poly-roulette-selected))
    }

    .dark\:data-\[selected\=true\]\:text-poly-blue-light[data-selected=true]:is(.dark *) {
        color: hsl(var(--poly-blue-light))
    }

    .dark\:data-\[selected\=true\]\:text-poly-orange-light[data-selected=true]:is(.dark *) {
        color: hsl(var(--poly-orange-light))
    }

    .dark\:data-\[selected\=true\]\:text-poly-red-light[data-selected=true]:is(.dark *) {
        color: hsl(var(--poly-red-light))
    }

    .dark\:data-\[selected\=true\]\:text-poly-yellow-light[data-selected=true]:is(.dark *) {
        color: hsl(var(--poly-yellow-light))
    }

    @media (min-width:640px) {
        .sm\:col-span-1 {
            grid-column: span 1/span 1
        }

        .sm\:col-span-2 {
            grid-column: span 2/span 2
        }

        .sm\:col-span-4 {
            grid-column: span 4/span 4
        }

        .sm\:col-start-1 {
            grid-column-start: 1
        }

        .sm\:col-start-10 {
            grid-column-start: 10
        }

        .sm\:col-start-11 {
            grid-column-start: 11
        }

        .sm\:col-start-12 {
            grid-column-start: 12
        }

        .sm\:col-start-13 {
            grid-column-start: 13
        }

        .sm\:col-start-2 {
            grid-column-start: 2
        }

        .sm\:col-start-3 {
            grid-column-start: 3
        }

        .sm\:col-start-4 {
            grid-column-start: 4
        }

        .sm\:col-start-5 {
            grid-column-start: 5
        }

        .sm\:col-start-6 {
            grid-column-start: 6
        }

        .sm\:col-start-7 {
            grid-column-start: 7
        }

        .sm\:col-start-8 {
            grid-column-start: 8
        }

        .sm\:col-start-9 {
            grid-column-start: 9
        }

        .sm\:col-start-\[14\] {
            grid-column-start: 14
        }

        .sm\:col-end-10 {
            grid-column-end: 10
        }

        .sm\:col-end-12 {
            grid-column-end: 12
        }

        .sm\:col-end-4 {
            grid-column-end: 4
        }

        .sm\:col-end-6 {
            grid-column-end: 6
        }

        .sm\:col-end-8 {
            grid-column-end: 8
        }

        .sm\:col-end-\[14\] {
            grid-column-end: 14
        }

        .sm\:row-span-1 {
            grid-row: span 1/span 1
        }

        .sm\:row-span-3 {
            grid-row: span 3/span 3
        }

        .sm\:row-start-1 {
            grid-row-start: 1
        }

        .sm\:row-start-2 {
            grid-row-start: 2
        }

        .sm\:row-start-3 {
            grid-row-start: 3
        }

        .sm\:row-start-4 {
            grid-row-start: 4
        }

        .sm\:row-start-5 {
            grid-row-start: 5
        }

        .sm\:block {
            display: block
        }

        .sm\:flex {
            display: flex
        }

        .sm\:hidden {
            display: none
        }

        .sm\:w-\[12\%\] {
            width: 12%
        }

        .sm\:w-\[13\%\] {
            width: 13%
        }

        .sm\:w-\[20\%\] {
            width: 20%
        }

        .sm\:w-\[26\%\] {
            width: 26%
        }

        .sm\:w-\[9\%\] {
            width: 9%
        }

        .sm\:max-w-sm {
            max-width: 24rem
        }

        .sm\:grid-cols-\[repeat\(14\2c 1fr\)\] {
            grid-template-columns: repeat(14, 1fr)
        }

        .sm\:grid-rows-5 {
            grid-template-rows: repeat(5, minmax(0, 1fr))
        }

        .sm\:flex-row {
            flex-direction: row
        }

        .sm\:flex-col {
            flex-direction: column
        }

        .sm\:justify-end {
            justify-content: flex-end
        }

        .sm\:gap-2 {
            gap: .5rem
        }

        .sm\:gap-8 {
            gap: 2rem
        }

        .sm\:space-x-2>:not([hidden])~:not([hidden]) {
            --tw-space-x-reverse: 0;
            margin-right: calc(.5rem * var(--tw-space-x-reverse));
            margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
        }

        .sm\:rounded-lg {
            border-radius: var(--radius)
        }

        .sm\:border-l {
            border-left-width: 1px
        }

        .sm\:border-t-0 {
            border-top-width: 0
        }

        .sm\:px-3 {
            padding-left: .75rem;
            padding-right: .75rem
        }

        .sm\:px-4 {
            padding-left: 1rem;
            padding-right: 1rem
        }

        .sm\:text-left {
            text-align: left
        }

        .sm\:text-base {
            font-size: 1rem;
            line-height: 1.5rem
        }

        .sm\:\[writing-mode\:unset\] {
            writing-mode: unset
        }
    }

    @media (min-width:768px) {
        .md\:flex {
            display: flex
        }

        .md\:h-6 {
            height: 1.5rem
        }

        .md\:w-6 {
            width: 1.5rem
        }

        .md\:w-\[10\%\] {
            width: 10%
        }

        .md\:w-\[12\.5\%\] {
            width: 12.5%
        }

        .md\:w-\[15\%\] {
            width: 15%
        }

        .md\:w-\[21\%\] {
            width: 21%
        }

        .md\:w-\[23\.5\%\] {
            width: 23.5%
        }

        .md\:w-\[8\%\] {
            width: 8%
        }

        .md\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr))
        }

        .md\:space-y-6>:not([hidden])~:not([hidden]) {
            --tw-space-y-reverse: 0;
            margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
            margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
        }
    }

    @media (min-width:1024px) {
        .lg\:block {
            display: block
        }

        .lg\:flex {
            display: flex
        }

        .lg\:\!hidden {
            display: none !important
        }

        .lg\:hidden {
            display: none
        }

        .lg\:w-\[10\%\] {
            width: 10%
        }

        .lg\:w-\[11\%\] {
            width: 11%
        }

        .lg\:w-\[15\%\] {
            width: 15%
        }

        .lg\:w-\[19\.5\%\] {
            width: 19.5%
        }

        .lg\:w-\[21\%\] {
            width: 21%
        }

        .lg\:w-\[8\.5\%\] {
            width: 8.5%
        }

        .lg\:grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr))
        }

        .lg\:grid-cols-4 {
            grid-template-columns: repeat(4, minmax(0, 1fr))
        }

        .lg\:flex-row {
            flex-direction: row
        }

        .lg\:flex-col {
            flex-direction: column
        }

        .lg\:border-l {
            border-left-width: 1px
        }

        .lg\:border-t-0 {
            border-top-width: 0
        }

        .lg\:p-5 {
            padding: 1.25rem
        }

        .lg\:px-1 {
            padding-left: .25rem;
            padding-right: .25rem
        }

        .lg\:px-8 {
            padding-left: 2rem;
            padding-right: 2rem
        }

        .lg\:pt-\[81px\] {
            padding-top: 81px
        }
    }

    @media (min-width:1280px) {
        .xl\:block {
            display: block
        }

        .xl\:w-\[10\%\] {
            width: 10%
        }

        .xl\:w-\[12\%\] {
            width: 12%
        }

        .xl\:w-\[16\%\] {
            width: 16%
        }

        .xl\:w-\[19\%\] {
            width: 19%
        }

        .xl\:w-\[23\%\] {
            width: 23%
        }

        .xl\:w-\[8\%\] {
            width: 8%
        }

        .xl\:px-3 {
            padding-left: .75rem;
            padding-right: .75rem
        }

        .xl\:text-xl {
            font-size: 1.25rem;
            line-height: 1.75rem
        }
    }

    .\[\&\:has\(\[data-state\=checked\]\)\>div\]\:border-foreground:has([data-state=checked])>div {
        border-color: hsl(var(--foreground))
    }

    .\[\&\:has\(\[data-state\=checked\]\)\>div\]\:bg-foreground:has([data-state=checked])>div {
        background-color: hsl(var(--foreground))
    }

    .\[\&\:has\(\[data-state\=checked\]\)\>div\]\:text-background:has([data-state=checked])>div {
        color: hsl(var(--background))
    }

    .\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role=checkbox]) {
        padding-right: 0
    }

    .\[\&\>span\]\:line-clamp-1>span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1
    }

    .\[\&\>tr\]\:last\:border-b-0:last-child>tr {
        border-bottom-width: 0
    }

    .\[\&_tr\:last-child\]\:border-0 tr:last-child {
        border-width: 0
    }

    .\[\&_tr\]\:border-b tr {
        border-bottom-width: 1px
    }

}