@media (max-width: 768px) {
  .slick-slide img {
    height: 50px;
    
  }
  .slick-active{
    border-radius: 6px; 
  }
}

@media (min-width: 768px) {
  .slick-slide img {
    height: 50px;
    
  }
  .slick-active{
    border-radius: 6px; 
  }
}