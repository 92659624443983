

@keyframes flipHeads {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(540deg);
  }
}

@keyframes flipTails {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(720deg);
  }
}

.animate-flip-heads {
  animation: flipHeads 2s ease-out forwards;
}

.animate-flip-tails {
  animation: flipTails 2s ease-out forwards;
}

.backface-hidden {
  backface-visibility: hidden;
}

.rotate-y-180 {
  transform: rotateY(-180deg);
}

.transform-style-preserve-3d {
  transform-style: preserve-3d;
}