@keyframes waterfall {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
  @keyframes fadeInUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .table-body {
    position: relative;
    overflow: hidden;
    height: 100%; /* Set a height for your table */
  }
  
  .table-row {
    display: flex;
    animation: waterfall 10s linear infinite;
  }
  
  .table-row:nth-child(even) {
    animation-delay: 2s; /* Stagger rows for a natural effect */
  }
  
  .table-row-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  