@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'coinflip';
  src: url('../src/Fonts/coinflip.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Apply the custom font to the body */



body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'coinflip', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow-x: hidden;
  width: 100%;
}
